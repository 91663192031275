.nav {
  width: 100%;
  color: #1a202c;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav a {
  color: inherit;
  text-decoration: none;
}

.nav-link {
  display: block;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 100;
  transition: background-color 100ms;
}

.nav-link:hover {
  background-color: #ccc;
}

.nav-logo {
  height: 4rem;
  align-self: center;
  margin: 1rem;
}

.submenu-link {
  padding: 10px;
  text-transform: uppercase;
  font-weight: 100;
  display: inline-block;
}

.submenu-outer {
  display: block;
  position: relative;
}

.submenu-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.submenu-outer:hover .submenu-content {
  display: inline-block;
}