.space-2 {
  margin: 1rem;
  padding: 1rem;
}

.space-4 {
  margin: 2rem;
  padding: 2rem;
}

.button {
  border-color: #474849;
  border-width: 2px;
  border-radius: 0.25rem;
  background-color: inherit;
  box-shadow: none;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.blue-button {
  text-transform: uppercase;
  padding: 13px 23px;
  border-width: 2px;
  border-radius: 0.25rem;
  border-style: solid;
  border-color: #38A3BD;
  background-color: white;
  color: #38A3BD;
  display: inline-block;
  cursor: pointer;
}

a.blue-button {
  text-decoration: none;
}

.clickable-link {
  text-decoration: underline;
  color: #38A3BD;
}

.strong-paragraph {
  color: #444;
  font-size: 22px;
  line-height: 33px;
  font-weight: 300;
}

.paragraph-with-header-header {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;
  display: block;
  padding-right: 2em;
}