.footer-wrapper {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer-block {
  line-height: 1.25rem;
  margin-bottom: 1rem;
}

.footer-banner {
  width: 100%;
  margin-bottom: 2rem;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 50px;
}

.footer-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer-item {
  display: block;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .footer-col {
    width: 33.3%;
  }
}

@media (max-width: 768px) {
  .footer-col {
    width: 100%;
  }
}